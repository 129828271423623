<template>
    <div class="SpareClassList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="spareClassForm" ref="spareClassForm">
                    <el-form-item label="分类名称">
                        <el-input v-model="spareClassForm.subClassDesc" placeholder="请输入分类名称查询"></el-input>
                    </el-form-item>
                    <el-form-item label="上级分类">
                        <el-select placeholder="请选择上级分类名称查询" v-model="spareClassForm.superClassCode">
                            <el-option v-for="(item, index) in oneClassList" :key="index" :label="item.subClassDesc"
                                :value="item.subClassCode"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button  class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="clearfix">
                    <el-button  class="btn" @click="$router.push({ name: 'spareClass-create' })"
                        icon="el-icon-circle-plus-outline">新增</el-button>
                </div>
            </div>

            <!-- 列表 -->
            <el-table :data="spareClassList" border  style="width: 100%; font-size: 13px;"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="subClassCode" label="分类编号" align="center"> </el-table-column>
                <el-table-column prop="subClassDesc" label="分类名称" align="center">
                </el-table-column>
                <el-table-column prop="superClassCode" label="父类编号" align="center">
                </el-table-column>
                <el-table-column prop="level_text" label="级别" align="center">
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" align="center">
                </el-table-column>

                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini"  title="修改"
                            @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="text" size="mini"  title="删除" class="delColor"
                            @click="del(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="spareClassForm.pageNum" :page-sizes="[5, 10, 20, 50, 100]"
                :page-size="spareClassForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>


        </el-card>
    </div>
</template>
<script>
import { searchSpareClass, querySpareOneClass, delSpareClass } from '@/api/spareclass.js'
export default {
    data() {
        return {
            spareClassList: [],
            oneClassList: [],
            spareClassForm: {
                subClassDesc: '',
                superClassCode: '',
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
        }
    },
    mounted() {
        this.loadSelect()
        this.searchSpareClass()
    },
    methods: {
        handleSizeChange(val) {
            this.spareClassForm.pageSize = val;
            this.spareClassForm.pageNum = 1;
            this.searchSpareClass();
        },
        handleCurrentChange(val) {
            this.spareClassForm.pageNum = val;
            this.searchSpareClass();
        },
        //查询
        searchSpareClass() {
            searchSpareClass(this.spareClassForm).then(res => {
                if (res.code === '000000') {
                    this.spareClassList = res.t.list
                    this.total = res.t.total
                    var count = 1;
                    this.spareClassList.forEach((item) => {
                        item.seq = count++
                        item.level === 0 ? (item.level_text = '一级') : (item.level_text = '二级')
                        // count= count +1;
                    })
                }

            })

        },
        async loadSelect() {
            await querySpareOneClass().then(res => {
                if (res.code === '000000') {
                    this.oneClassList = res.t
                }
            })
        },

        handleEdit({ id }) {
            this.$router.push({
                name: 'spareClass-edit',
                params: {
                    id
                }
            })
        },

        //删除
        del({id,subClassDesc}) {
            this.$confirm('是否删除名称为“'+subClassDesc+'”的备品备件分类？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log(id)
                delSpareClass(id).then(res => {
                    if (res.code === '000000') {
                        this.$message.success('删除成功')
                        this.loadSelect()
                        this.searchSpareClass()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })

        },
        handleSearch() {
            this.searchSpareClass(this.spareClassForm)
        },
        handleReset() {
            this.spareClassForm = {}
            this.searchSpareClass(this.spareClassForm)
        }
    }
}
</script>


<style lang="scss" scoped>
.SpareClassList {

    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;
    }

}
</style>